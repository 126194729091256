<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <div v-if="title" class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-2">
            <span class="tgcss-text-sm tgcss-font-bold grow">{{title}}</span> 
            <router-link :to="{ name: 'ApplicationNews' , params: { category_id: cat, }}" class="tgcss-opacity-70 tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800">مشاهده‌همه</router-link>                
        </div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div  v-if="!loading && slider.items" v-for="item in slider.items.data" :key="item.id" class="tgcss-p-4 tgcss-rounded-lg tgcss-mb-3 tgcss-bg-black tgcss-relative tgcss-overflow-hidden"
              :style="{ backgroundImage: 'url(' + item.image + ')' }" style="background-size: cover;background-position: center;"
        >
            <div class=" tgcss-z-10 tgcss-relative tgcss-flex tgcss-items-center">
                <div class="tgcss-grow">
                    <div class="tgcss-font-medium tgcss-w-2/3 tgcss-text-base tgcss-text-white tgcss-line-clamp-2 tgcss-text-ellipsis tgcss-overflow-hidden tgcss-h-[50px] tgcss-mb-2">
                        {{item.title}}
                    </div>
                    <router-link :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }}"  class="!tgcss-bg-emerald-500 tgcss-inline-block !tgcss-text-white !tgcss-no-underline tgcss-pt-1 tgcss-pb-1.5 tgcss-px-4 tgcss-text-xs tgcss-font-medium tgcss-rounded-3xl">مشاهده ادامه ...</router-link>
                </div>
                <div>
                    <div class="tgcss-text-center tgcss-bg-white tgcss-rounded-md tgcss-overflow-hidden">
                        <div class="tgcss-w-full tgcss-py-1 tgcss-font-bold tgcss-px-3">{{ parseDate(item.jalali_created_at).day }}</div>
                        <div class="tgcss-w-full tgcss-py-1 tgcss-font-bold tgcss-px-3 tgcss-bg-gray-100">{{ parseDate(item.jalali_created_at).monthName }}</div>
                        <div class="tgcss-w-full tgcss-py-1 tgcss-font-bold tgcss-px-3 tgcss-bg-gray-300">{{ parseDate(item.jalali_created_at).year }}</div>
                    </div>
                </div>
            </div>
            <div class="tgcss-bg-gradient-to-r tgcss-from-transparent tgcss-to-black tgcss-absolute tgcss-inset-0"></div>
            <div class="tgcss-bg-gradient-to-l tgcss-from-transparent tgcss-to-black/50 tgcss-absolute tgcss-inset-0"></div>

            
 
        </div>


    </div>
</template>
    
<script>
    export default {
        name: 'newsBanner',
        props: ['cat', 'count', 'title', 'type'],
        components: {},
        data() {
            return {
                slider:[],
                slider_cat:0,
                loading:false
            }
        },
        mounted() {
            this.getSlider();
        },
        computed: {},
        methods: {
            parseDate(dateString) {
                // تاریخ ورودی: یکشنبه 5 اسفند 1403 ساعت 11:31
                const months = [
                    'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
                    'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
                ];
                
                // روزهای هفته
                const daysOfWeek = [
                    'شنبه', 'یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه'
                ];
                
                // جدا کردن روز، ماه، سال
                const dateParts = dateString.match(/(\D+)\s(\d+)\s(\D+)\s(\d+)/);

                const dayOfWeek = dateParts[1];  // روز هفته (فارسی)
                const day = dateParts[2];        // روز
                const month = months.indexOf(dateParts[3]) + 1;  // ماه به شماره
                const monthName = dateParts[3];  // ماه به اسم فارسی
                const year = dateParts[4];       // سال
                
                return { dayOfWeek, day, month, monthName, year };
            },
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getSlider(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.slider = res.responseJSON.response;  
                        this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  